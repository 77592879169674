import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import './PokerTournament.css'; // Import the updated styles for the form
import useAxios from '../useAxios';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-select';

const PokerTournament = () => {
  const { id } = useParams();

  const [axios] = useAxios(true);
  const navigate = useNavigate();

  const [types, setTypes] = useState([{ id: "1", name: "Max 6 Turbo" }]);
  const [players, setPlayers] = useState(null);
  const [playerToAdd, setPlayerToAdd] = useState(null);
  const [tournament, setTournament] = useState(null);

  useEffect(() => {
    axios.get('players', setPlayers)
    axios.get('poker/tournament/'+id, setTournament)
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTournament((prevcomp) => ({ ...prevcomp, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setTournament((prevcomp) => ({ ...prevcomp, [name]: checked }));
  };

  const handleDateTimeChange = (datetime) => {
    setTournament((prevcomp) => ({ ...prevcomp, dateTime: datetime }));
  };

  const handleTypeChange = (type) => {
    setTournament((prevcomp) => ({ ...prevcomp, type: type }));
  };

  const onSave = () => {
    axios.post('poker/tournament/' + id, tournament, (result) => navigate('poker/tournaments'));
  };

  const onCancel = () => {
    navigate('/poker/tournaments');
  }

  if (tournament === null || players === null)
  {
    return <h1>Loading</h1>;
  }

  return (
    <div className="edit-tournament-container">
      <h2>Edit Tournament</h2>
      <form>
        <div className="edit-tournament-grid">
          <label>Name:</label>
          <input type="text" name="name" value={tournament.name} onChange={handleChange} />
          <br />
          <label>Date/Time:</label>
          <DatePicker
            selected={new Date(tournament.plannedDateTime)}
            onChange={handleDateTimeChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="dd MMM yyyy HH:mm"
          />
          <br />
        <label>Type: </label>
        { id === "new" ? <Select
          name="type"
          value={tournament.type}
          onChange={(option) => handleTypeChange(option)}
          options={types}
          getOptionValue={(option) => option.id}  // Specify the property used for value
          getOptionLabel={(option) => option.name}  // Specify the property used for label
          isSearchable={false} /> : <label>{tournament.type.name}<br/></label> }
        <br />
        </div>
        <div>
          <button type="button" onClick={onSave} disabled={tournament.status !== 1}>
            Save
          </button>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default PokerTournament;
