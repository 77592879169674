import React, { useState, useEffect, useRef } from 'react';
import useSessionStorage from './useSessionStorage';
import { useLocation } from 'react-router-dom';

import './NavBar.css'

import { useNavigate } from 'react-router-dom';

import ajb from './images/ajb.png';

const NavBar = ({visible, menus, logout}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tenant, setTenant] = useSessionStorage('tenant');

  return (
    <nav className="nav-bar" style={{ display: visible ? 'flex' : 'none' }}>
      <ul className="nav-list">
        <li><img src={ajb} /></li>
        <li><b>{tenant?.name}</b></li>
      </ul>
      <ul className="nav-list">
        {
          menus.map(x => <li key={x.label}><a style={{fontWeight: location.pathname == x.url ? "bold" : ""}} onClick={() => navigate(x.url)}>{x.label}</a></li>) 
        }
        <li><a onClick={logout}>Logout</a></li>
      </ul>
      <ul className="nav-list">
        <li style={{marginRight:"40px"}}><a>{process.env.REACT_APP_VERSION}</a></li>
      </ul>
    </nav>
  );
};

export default NavBar;
