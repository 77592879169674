import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import "./PokerTimer.css"; // Import the CSS file
import useAxios from "../useAxios";

import moment from "moment";

const PokerTimer = ({ readonly }) => {
  const { id } = useParams();
  const [tournament, setTournament] = useState(null);
  const [levelEnd, setLevelEnd] = useState(moment(0));
  const [levelRemaining, setLevelRemaining] = useState(moment(0));
  const [lastPoll, setLastPoll] = useState(moment());

  const [axios] = useAxios(false);

  const getTournament = async (id) => {
    axios.get(`public/poker/tournament/${id ?? ""}`, (x) => {
      setLevelEnd(moment().add(x.levels.current.remaining, "seconds"));
      setTournament(x);
      setLastPoll(moment());
    });
  };

  useEffect(() => {
    getTournament(id);
  }, []);

  useEffect(() => {
    const tournamentIntervalId = setInterval(() => {
      getTournament(id);
    }, 20000);

    const clockIntervalId = setInterval(() => {
      if (tournament !== null) {
        const seconds = levelEnd.diff(moment(), "seconds");
        if (seconds < 0)
        {
          getTournament(id);
        }
        setLevelRemaining(moment.utc(seconds * 1000));
      }
    }, 500);

    return () => {
      clearInterval(tournamentIntervalId);
      clearInterval(clockIntervalId);
    };
  }, [tournament]);

  const getBlindsFontSize = () => {
    return -tournament.levels.current.blinds.length / 2 + 14 + "vh";
  };

  if (tournament === null) {
    return (
      <div
        className="poker-timer-container"
        style={{ "--header": readonly ? "0px" : "50px" }}
      >
        Loading...
      </div>
    );
  }

  return (
    <div
      className="poker-timer-container"
      style={{ "--header": readonly ? "0px" : "50px" }}
    >
      <div className="entries">
        <div>
          <h4>Entries</h4>
          <p>{tournament.stats.entries}</p>
        </div>
      </div>
      <div className="remaining">
        <div>
          <h4>Remaining</h4>
          <p>{tournament.stats.remaining}</p>
        </div>
      </div>
      <div className="rebuys">
        <div>
          <h4>Rebuys / Add-Ons</h4>
          <p>{tournament.stats.rebuys} / {tournament.stats.addons}</p>
        </div>
      </div>
      <div className="avg-chips">
        <div>
          <h4>Avg Chips</h4>
          <p>{tournament.stats.chips.average}</p>
        </div>
      </div>
      <div className="total-chips">
        <div>
          <h4>Total Chips</h4>
          <p>{tournament.stats.chips.total}</p>
        </div>
      </div>
      <div className="total-pot">
        <div>
          <h4>Total Pot</h4>
          <p>£{tournament.pot.total}</p>
        </div>
      </div>

      <div className="title">
        <div>
          <h4>{tournament.name}</h4>
          <h4>{tournament.type}</h4>
          <h4>{readonly ? "readonly" : "admin"}</h4>
        </div>
      </div>
      <div className="level-remaining">
        <div>
          <h4>{tournament.levels.current.name}</h4>
          {levelRemaining.format("mm:ss")}
        </div>
      </div>
      <div
        className="blinds"
        style={{
          fontSize: getBlindsFontSize(),
        }}
      >
        <div>
          <h4>Blinds</h4>
          <div>{tournament.levels.current.blinds}</div>
        </div>
      </div>
      <div className="next">
        <div>
          <h4>Next Level</h4>
          <p>{tournament.levels.next?.name}</p>
          <p>{tournament.levels.next?.blinds}</p>
        </div>
      </div>

      <div className="elasped">
        <div>
          <h4>Duration</h4>
          <p>{moment.utc((tournament.elasped + moment().diff(lastPoll, "seconds")) * 1000).format("mm:ss")}</p>
        </div>
      </div>
      <div className="next-break">
        <div>
          <h4>Next Break</h4>
          <p>{tournament.levels.break.name}</p>
        </div>
      </div>
      <div className="payouts">
        <div>
          <h4>Payouts</h4>
          {tournament.pot.payout.map((x) => {
            return <p>£{x}</p>;
          })}
        </div>
      </div>
    </div>
  );
};

export default PokerTimer;
