import React, { useState, useEffect } from 'react';
import './PokerTournementsList.css'; // Import your CSS file

import { useNavigate } from 'react-router-dom';

import useAxios from '../useAxios';

import moment from 'moment';

const PokerTournementsList = () => {  
    const navigate = useNavigate();
    const [axios] = useAxios(true);

    const [tournaments, setTournaments] = useState([]);

    useEffect(() => {
      axios.get('poker/tournaments', setTournaments);
    }, []);

  return (
    <div className="tournaments-container">
      <h2>tournaments</h2>
      <table className="tournaments-table">
        <thead>
            <tr>
                <th>Name</th>
                <th>Date/Time</th>
                <th>Type</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr key="new">
                <td></td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/tournament/new')}>New</button>
                </td>
            </tr>
            {tournaments.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)).map((tournament) => (
            <tr key={tournament.id}>
                <td>{tournament.name}</td>
                <td>{moment(tournament.dateTime).format("DD/MM/yyyy HH:mm")}</td>
                <td>{tournament.type?.name}</td>
                <td>
                    <button className="edit-button" onClick={() => navigate('/poker/tournament/' + tournament.id)}>Edit</button>
                </td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PokerTournementsList;
