// src/Home.js

import React, { useEffect, useState } from 'react';
import './PokerDashboard.css';
import { useNavigate } from 'react-router-dom';

import useAxios from '../useAxios';

import playerIcon from '../images/player.png';
import ballIcon from '../images/8ball.png';
import matchIcon from '../images/match.png';
import productsIcon from '../images/products.png';

const Tile = ({ title, image, onclick }) => (
  <a onClick={onclick}>
    <div className="tile">
      <h3>{title}</h3>
      <img src={image}></img>
    </div>
  </a>
);

const PokerDashboard = ({ logout }) => {
  const navigate = useNavigate();
  const [axios] = useAxios(true, logout);

  useEffect(() => {
  }, []);

  return (
    <div className="home-container">
      <h2>Configuration</h2>
      <div className="home-container-group">
        <Tile title="Tournaments" image={playerIcon} onclick={() => navigate('/poker/tournaments')} />
      </div>
    </div>
  );
};

export default PokerDashboard;
